/* link fonts */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

.App {
  text-align: center;
  font-family: 'Poppins';
  padding: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
  flex-direction: row;
}

.login-text-field {
  font-family: 'poppins';
  font-weight: 500;
  font-size: 14px;
  color: #6b7a99;
}

.login-left {
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
  justify-content: right;
  align-items: end;
}

.login-right {
  display: flex;
  flex: 0 0 50%;
  margin: 0;
  padding: 0;
  justify-content: end;
}

.login-right img {
  width: auto;
  height: 100%;
  position: absolute;
}

.login-box {
  width: 60%;
  margin: 25% auto;
}

.bxi-logo-img {
  height: 30%;
}

.MuiButton-label .btn-login {
  font-family: 'poppins';
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  margin-top: 10px;
}

.login-title {
  display: flex;
  justify-content: center;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 23px;
  color: #6b7a99;
}

.selectDropdownStyle {
  width: 100%;
  background: rgba(243, 246, 249, 1);
  border-radius: 15px !important;
}

.selectDropdownLabel {
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  width: 40%;
}

.selectDropdownStyle .MuiSelect-select,
.MuiMenuItem-root {
  font-size: 12px !important;
  font-family: 'poppins' !important;
  font-weight: 400 !important;
}

.page-header-dropwon-section {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 40px;
}

.page-header-dropwon-section .MuiFormControl-root {
  width: 30%;
}

.horizontal-line {
  margin: 20px 0;
  border-color: rgba(240, 240, 240, 1);
}

table.common-table {
  width: 100%;
  text-align: center;
}

table.common-table thead {
  color: rgba(146, 158, 174, 1);
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins';
}

table.common-table tbody {
  color: rgba(107, 122, 153, 1);
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
}

.custom-y-axis .rv-xy-plot__axis--y {
  width: 200px !important;
  /* Set your desired width here */
  /* Add other styling properties as needed */
}

.form-opacity {
  opacity: 0.7;
  /* Set your desired opacity value here */
  pointer-events: none;
  /* This ensures that the form cannot be interacted with while it's semi-transparent */
}

.members-graph-page-container {
  background: #fafbfc;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 600px;
  margin: 10px 0;
}

.custom-date-input::-webkit-calendar-picker-indicator {
  filter: invert(1);
  -webkit-filter: invert(1);
}

.total-products-badge.custom-tab {
  justify-content: space-between;
  display: flex;
}

.total-products-badge.custom-tab span {
  cursor: pointer;
}
.css-18ftw0b-MuiChartsSurface-root {
  --ChartsLegend-itemWidth: 100px;
  --ChartsLegend-itemMarkSize: 21px;
  --ChartsLegend-rootSpacing: 7px;
  --ChartsLegend-labelSpacing: 8px;
  --ChartsLegend-rootOffsetX: 70px;
}

.triple-spinner {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #445fd2;
  -webkit-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}
.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #445fd2;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}
.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #445fd2;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

::-webkit-scrollbar {
  display: none;
}

div[style*="position: absolute; pointer-events: none; color: rgba(130, 130, 130, 0.62); z-index: 100000; width: 100%; text-align: center; bottom: 50%; right: 0px; letter-spacing: 5px; font-size: 24px;"] {
  display: none !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}