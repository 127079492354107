.custom-container {
    display: flex;
    flex-direction: row;
}

.custom-leftbar {
    width: 20%;
    padding: 10px;
    border-right: 1px solid gray;
}

.custom-rightbar {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1300px;
}

.inner-left {
    flex: 0 0 59%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    max-height: 728px;

}

.inner-left-top {
    border: 1px solid rgba(230, 233, 238, 1);
    height: 400px;
    margin: 10px 0 0 0;
    min-height: 470px;
    border-radius: 10px;
}

.inner-left-bottom {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
}


.inner-right {
    flex: 0 0 40%;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;

}

.inner-right a {
    /* flex: 0 0 40%; */
    margin: 6px 0;
    width: 225px;
    cursor: pointer;
}

.inner-bottom {
    flex: 0 0 100%;
    border: 1px solid black;
}

.graph-heading {
    font-size: 22px;
    line-height: 22px;
    padding: 0 20px;
    font-family: 'Poppins';
    font-weight: 600;
}

.graph-heading small {
    /* width: 100%; */
    margin-left: 5px;
    display: inline-block;
    font-size: 11px;
    font-family: Poppins;
    color: rgba(107, 122, 153, 1);
    font-weight: 500;
}


.grapharrow {
    border-radius: 10px;
    position: absolute;
    width: 42px;

    font-size: 10px;
    padding: 3px;
    left: 13px
}

.membership-header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 0 0 10px 0;
}

.membership-header-left {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 500;
}
.membership-header-left small {
    padding: 0 20px;
    color: #6B7A99;
}
.membership-header-left span {
    border: 1px solid #E6E9EE;
    padding: 5px;
    margin: 0 10px;
}

.membership-header-right {
    width: 60%;
    flex-direction: row;
    display: flex;
    justify-content: end;
}
h5.graph-heading {
    font-size: 15px;
    color: #343434;
    font-weight: 500;
    line-height: 15px;
    margin: 10px 0 5px 0;
}

span.premium-plus-count, span.premium-count {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    margin: 0 0 0 20px;
    align-items: baseline;
}

span.premium-plus-count {
    border-bottom: 5px solid #445FD2;
}
span.premium-count{
    border-bottom: 5px solid #BD73EB;
}
.membership-header-right span.percentage{
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    color: #3CB928;
}

.membership-header-right span.amout {
    color: #343434;
    font-size: 15px;
    font-weight: 600;
}
.disabled-link {
    cursor: not-allowed !important;
}
