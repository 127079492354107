.speech-bubble {
  position: relative;
  width: '260px';
  height: '90px';
  border-radius: 6px;
  background: rgba(235, 235, 235, 0.24);
  box-shadow: 0px 4px 4px 0px rgba(205, 205, 205, 0.25);
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: rgba(235, 235, 235, 0.24);
  border-left: 0;
  margin-top: -20px;
  margin-left: -20px;
}
