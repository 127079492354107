.fields-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fields-box .MuiBox-root {
  flex: 0 0 50%;
  margin: 0 0 20px 0;
}

.fields-box.full-width .MuiBox-root {
  flex: 0 0 100%;
}

.fields-box .MuiBox-root label {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  display: inline-block;
  color: #6b7a99;
  margin-bottom: 5px;
}

.fields-box .MuiBox-root input,
.fields-box .MuiBox-root select {
  width: 407px;
  border: 2px solid #e6e9ee;
  background: #ffffff;
  border-radius: 4px;
  padding: 10px;
}

.rights-of-user h6 {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 16px;
  color: #6b7a99;
  margin: 0;
}

.rights-of-user p {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #acacac;
  margin: 0 0 50px 0;
}

.center-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.center-buttons button {
  width: 175px;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
}

.center-buttons .cancel-button {
  background: #fff;
  color: #445fd2;
}

.member-details-button {
  width: 131px;
  background: #445fd2;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transperent-badge {
  background: #fff;
  border: 1px solid #e6e9ee;
  border-radius: 10px;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  color: #6b7a99;
}

.input-field {
  width: 200px;
  border: 2px solid #e6e9ee;
  background: #ffffff;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #6b7a99;

  
}

.select-dropdown {
  width: 100%;
  border: 2px solid #e6e9ee;
  background: #ffffff;
  border-radius: 4px;
  padding: 5px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: #6b7a99;
}

.gradient-dropwon {
  width: 100%;
  border-radius: 4px;
  border: 1.5px solid #E6E9EE;  
  background: transparent;
  padding: 8px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  color: #445FD2;
}