.marketplace-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.marketplace-filters .MuiBox-root {
  flex: 0 0 25%;
  margin: 8px 0;
}

.marketplace-filters .MuiBox-root label {
  color: #6b7a99;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 10px;
  width: 70px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}

.marketplace-filters .MuiBox-root select,
.marketplace-filters input[type='text'] {
  width: 165px;
  background: #f3f6f9;
  color: #1f1f1f;
  border-radius: 7px;
  padding: 10px;
  border: 1px solid #f3f6f9;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 10px;
}

.input-container {
  position: relative;
  width: 165px;
  display: inline-block;
}

.marketplace-filters input[type='text'] {
  padding-right: 30px;
  /* Add some padding to the right to make space for the icon */
}

.marketplace-filters .calendar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* 
.field-box-container {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.field-box-container .field-box {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
  width: 45%;
}

.field-box-container .field-box label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 9x;
}

.field-box-container .field-box input {
  width: 260px;
  border: 1px solid #e6e9ee;
  border-radius: 3px;
  font-family: 'Poppins';
  font-weight: 500;
  padding: 10px;
  font-size: 11x;
} */
.field-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.field-box-container .field-box {
  flex: 0 0 calc(48% - 10px);
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.field-box-container .field-box-full-width {
  flex: 0 0 100%;
  flex-direction: column;
  gap: 5px;
  margin: 5px 0;
}

.field-box-container .field-box label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 11px; 
}

.field-box-container .field-box input {
  width: 100%; 
  border: 1px solid #e6e9ee;
  border-radius: 3px;
  font-family: 'Poppins';
  font-weight: 500;
  padding: 10px;
  font-size: 11px;
}

.field-box-container .input-full-width input {
  width: 260px;
  border: 1px solid #e6e9ee;
  border-radius: 3px;
  font-family: 'Poppins';
  font-weight: 500;
  padding: 10px;
  font-size: 11x;
}

/* .field-box-half-width-box {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 200px,
} */

.button-container {
  display: flex;
  justify-content: center;
  margin: 5px 0;
  gap: 10px;
}

.button-container button {
  width: 100px;
  padding: 8px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.button-container button.cancel-button {
  background: #fff;
  color: #344054;
  border: 1px solid#E6E9EE;
}

.button-container button.save-button {
  background: #445fd2;
  color: #fff;
  border: 1px solid#445FD2;
}
